<template>
	<event-assignments-page v-bind="props"
							:my-tab-title="t('label.my_competitions')"
							:index-item-link="{to: '/events', text: t('action.to_competition_calendar')}"
							ref="pageComponent">

		<template v-if="$slots.assignment_skeletons" #assignment_skeletons>
			<slot name="assignment_skeletons"/>
		</template>

		<template v-if="$auth.loggedIn" #default="{isCommunityView}">
			<client-only>
				<container-fab-btn :visible="!isCommunityView" :hide-on-scroll="!$device.isDesktop">
					<btn :title="t('action.add_participation')"
						 color="success"
						 shadow
						 rounded-large
						 @click="addParticipation">
						<icon :icon="icons.create"/>
						<span>{{ t('action.add') }}</span>
					</btn>
					<slot name="action_buttons"/>
				</container-fab-btn>

				<add-manual-participation-modal v-model="showAddParticipationModal"
												user-key="my" :disable-upload="!props.forPast" @success="handleAddedParticipation"/>
			</client-only>
		</template>

		<template #missing_buttons>
			<btn @click="addParticipation" color="success">
				<icon :icon="icons.create"/>
				<span>{{ t('action.add_participation') }}</span>
			</btn>
		</template>
	</event-assignments-page>
</template>

<script setup lang="ts">
import {createIcon} from "@spoferan/nuxt-spoferan/icons";

const {t} = useI18n();
const {$auth, $apiFetch} = useNuxtApp();

const props = defineProps<{
	title: string,
	url: string,
	featureTitle: string,
	communityFeatureTitle: string,
	featureDescription: string,
	communityFeatureDescription: string,
	featureImage: string,
	itemLink?: object,

	// Whether the page is optimized for past events
	forPast?: boolean,
}>();

const icons = {
	create: createIcon
};

const pageComponent = ref(null);
const showAddParticipationModal = ref(false);
function addParticipation() {
	showAddParticipationModal.value = true;
}

async function handleAddedParticipation() {
	pageComponent.value.refresh();
}

</script>

